<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="getUnit"
    ></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <CAlert id="productUnitAlertSaveSuccess" color="success" v-if="isSuccess === true" :show="Number(3)">
            {{ $t('saveSuccess') }}
          </CAlert>
          <CAlert id="productUnitAlertDeleteSuccess" color="danger" v-if="isDeleteSuccess === true" :show="Number(3)">
            {{ $t('deleteSuccess') }}
          </CAlert>
          <div class="row">
            <div class="col-md-10 col-sm-9 col-8">
              <h2 class="font-weight-normal">
                {{ $t('management.unitManagement') }}
              </h2>
            </div>
            <div
              v-if="isEditData"
              class="col-md-2 col-sm-3 col-4 text-right text-success"
            >
              <CButton id="productUnitAddButton" block color="success" style="display: flex; align-items: center; justify-content: center;" @click="addModal()">
                <i class="fi fi-rr-plus" style="margin-right: 4px;"></i>
                {{ $t('addUnit') }}
              </CButton>
            </div>
          </div>
          <hr />
          <CRow>
            <CCol sm="12" lg="12">
              <CDataTable :items="updateItems()" id="productUnitTable" :fields="fields" hover sorter border>
                <!-- <template #num="{ item }">
                  <td class="text-dark" style="width: 2%">{{ item.num }}</td>
                </template> -->
                <template #enabled="{ item, index }">
                  <td class="py-2 text-center" v-if="isEditData">
                    <CSwitch
                      :id="'productUnitSwitch'+ index"
                      v-model="item.enabled"
                      :checked.sync="item.enabled"
                      color="success"
                      @update:checked="updateIsEnabled($event, item)"
                    />
                  </td>
                  <td class="py-2 text-center" v-else>
                    <CSwitch
                      :id="'productUnitSwitchDisabled'+ index"
                      :checked.sync="item.enabled"
                      v-model="item.enabled"
                      color="success"
                      disabled
                    />
                  </td>
                </template>
                <template #action="{ item, index }">
                  <td class="text-center">
                    <CRow>
                      <CCol>
                        <img :id="'productUnitEditIcon'+ index" @click="editModal(item)" src="/img/file-edit.png" style="width: 14px; height: 14px; cursor: pointer;"/>  
                      </CCol>
                      <CCol v-if="item.id !== '0' && isDeleteData">
                      <img :id="'productUnitDeleteIcon'+ index" @click="confirmdelete(item.objectId)"src="/img/trash.png" style="width: 14px; height: 14px;cursor: pointer; "/>
                      </CCol>
                    </CRow>
                  </td>
                </template>
                <template #no-items-view>
                  <div class="text-center my-5">
                    <h4 style="color: #ced2d8;">
                      {{ $t('noItem') }}
                    </h4>
                  </div>
                </template>
              </CDataTable>
            </CCol>
          </CRow>
          <pagination
            class="mt-4"
            :meta_data="meta_data"
            v-on:next="getUnit"
          ></pagination>
        </CCardBody>
      </CCard>
      <CModal id="productUnitAddModal" :show.sync="addUnitModal" :title="namemodal" centered>
        <CRow>
          <CCol md="3" sm="3">{{ $t('unit') }}</CCol>
          <CCol md="9" sm="9">
            <CAlert id="productUnitSaveErrorAlert" color="danger" v-if="saveErrorMsg" :show="Number(1)">
            {{ saveErrorMsg }}
            </CAlert>
            <CInput id="productUnitNameInput" v-if="validateUnitname"
            v-model="unitname"
            :placeholder="$t('unit')"
            :is-valid="validator"
            :invalid-feedback="$t('validateCategoryname')"
            />
            <CInput id="productUnitNameInputNoValidate" v-else
            v-model="unitname"
            :placeholder="$t('unit')"
            />
          </CCol>
          <CCol v-if="isactive == true" md="3" sm="3">{{ $t('active') }}</CCol>
          <CCol v-else-if="isactive == false" md="3" sm="3">
            {{ $t('inactive') }}
          </CCol>
          <CCol md="9" sm="9">
            <CSwitch
              id="productUnitSwitchActive"
              v-if="isactive === true"
              v-model="isactive"
              color="success"
              :value="isactive"
              :checked.sync="isactive"
            />
            <CSwitch
              id="productUnitSwitchInactive"
              v-else
              v-model="isactive"
              color="success"
              :checked.sync="isactive"
            />
          </CCol>
        </CRow>
        <template #footer>
          <CRow class="justify-content-around col-md-12">
            <CCol col="4">
              <CButton
                id="productUnitSaveButton"
                color="success"
                v-if="loadingSaveButton === false"
                @click="handlePopup"
                block
              >
                {{ $t('save') }}
              </CButton>
              <CButton
                id="productUnitSaveButtonLoading"
                v-else-if="loadingSaveButton === true"
                color="success"
                block
                disabled
              >
                <CSpinner color="white" size="sm" />
              </CButton>
            </CCol>
            <CCol col="4">
              <CButton
                id="productUnitCancelButton"
                v-if="loadingSaveButton === false"
                @click="addUnitModal = false"
                color="light"
                block
              >
                {{ $t('cancel') }}
              </CButton>
              <CButton
                id="productUnitCancelButtonLoading"
                v-else-if="loadingSaveButton === true"
                color="light"
                block
                disabled
              >
                {{ $t('cancel') }}
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>

      <CRow>
        <CModal
          id="productUnitDeleteModal"
          color="danger"
          :title="$t('confirmDeletUnit')"
          :show.sync="deleteModal"
          centered>
          <h4 class="mt-4 mb-4 text-center">{{ $t('dialigDeleteUnit') }}</h4>
          <template #footer>
            <CRow class="justify-content-around col-md-12">
              <CCol col="4">
                <CButton
                  id="productUnitDeleteButton"
                  v-if="loadingSaveButton === false"
                  @click="deleteUnit(itemDelete)"
                  color="danger"
                  block
                >
                  {{ $t('delete') }}
                </CButton>
                <CButton
                  id="productUnitDeleteButtonLoading"
                  v-else-if="loadingSaveButton === true"
                  color="danger"
                  block
                  disabled
                >
                  <CSpinner color="white" size="sm" />
                </CButton>
              </CCol>
              <CCol col="4">
                <CButton
                  id="productUnitCancelDeleteButton"
                  v-if="loadingSaveButton === false"
                  @click="deleteModal = false"
                  color="light"
                  block
                >
                  {{ $t('cancel') }}
                </CButton>
                <CButton
                  v-else-if="loadingSaveButton === true"
                  color="light"
                  disabled
                  block
                >
                  {{ $t('cancel') }}
                </CButton>
              </CCol>
            </CRow>
          </template>
        </CModal>
      </CRow>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import local from '@/services/local'
import Pagination from '@/containers/Pagination'
import moment from 'moment'
import permis from '@/util/permission'

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      isSuccess: false,
      isDeleteSuccess: false,
      shop: this.$store.getters.shopObjectId,
      data: [],
      unitname: '',
      namemodal: '',
      deleteModal: false,
      itemDelete: '',
      validateUnitname: '',
      keyword: '',
      itemId: '',
      itemObjectId: '',
      addUnitModal: false,
      isenabled: true,
      isactive: true,
      editdata: false,
      loadingButton: true,
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: null,
        limit: 50,
        itemCount: null,
      },
      loadingSaveButton: false,
      saveErrorMsg: '',
      isEcommerce:false
    }
  },
  created() {
    this.getUnit()
    this.shop = this.shops.find((i) => i.objectId === this.shop)
    this.handleEcommerce()
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date']),
    isPermission() {
      return permis.findPermissionRead('product', this.$route.path)
    },
    isEditData() {
      return permis.findPermissionEdit('product', this.$route.path)
    },
    isDeleteData() {
      return permis.findPermissionRemove('product', this.$route.path)
    },
    fields() {
      if (this.isEditData && !this.isEcommerce) {
        return [
          {
            key: 'num',
            label: this.$i18n.t('#'),
            sorter: false,
            _style: 'width:5%',
            _classes: 'text-dark font-weight-normal',
          },
          {
            key: 'name',
            label: this.$i18n.t('unit'),
            sorter: false,
            _style: 'width:55%',
            _classes: 'text-dark font-weight-normal',
          },
          {
            key: 'lastUpdated',
            label: this.$i18n.t('lastUpdate'),
            sorter: false,
            _style: 'width:20%',
            _classes: 'text-dark font-weight-normal',
          },
          {
            key: 'enabled',
            label: this.$i18n.t('active'),
            _classes: 'text-center text-dark font-weight-normal',
            sorter: false,
            _style: 'width:10%',
          },
          {
            key: 'action',
            label: '',
            _classes: 'text-center text-dark font-weight-normal',
            sorter: false,
            _style: 'width:10%',
          },
        ]
      } else if (this.isEcommerce){
        return [
          {
            key: 'num',
            label: this.$i18n.t('#'),
            sorter: false,
            _style: 'width:5%',
            _classes: 'text-dark font-weight-normal',
          },
          {
            key: 'name',
            label: this.$i18n.t('unit'),
            sorter: false,
            _style: 'width:45%',
            _classes: 'text-center text-dark font-weight-normal',
          },
          {
            key: 'lastUpdated',
            label: this.$i18n.t('lastUpdate'),
            sorter: false,
            _style: 'width:40%',
            _classes: 'text-center text-dark font-weight-normal',
          },
          {
            key: 'enabled',
            label: this.$i18n.t('active'),
            _classes: 'text-center text-dark font-weight-normal',
            sorter: false,
            _style: 'width:10%',
          },
        ]
      } else {
        return [
          {
            key: 'num',
            label: this.$i18n.t('#'),
            sorter: false,
            _style: 'width:5%',
            _classes: 'text-dark font-weight-normal',
          },
          {
            key: 'name',
            label: this.$i18n.t('unit'),
            sorter: false,
            _style: 'width:45%',
            _classes: 'text-center text-dark font-weight-normal',
          },
          {
            key: 'lastUpdated',
            label: this.$i18n.t('lastUpdate'),
            sorter: false,
            _style: 'width:40%',
            _classes: 'text-center text-dark font-weight-normal',
          },
          {
            key: 'enabled',
            label: this.$i18n.t('active'),
            _classes: 'text-center text-dark font-weight-normal',
            sorter: false,
            _style: 'width:10%',
          },
        ]
      }
    },
    items() {
      let data = this.data
      let detail = []
      const currentPage = (this.meta_data.current_page || 1) - 1
      const limit = this.meta_data.limit || 50

      for (let i = 0; i < data.length; i++) {
        const index = (currentPage * limit) + (i + 1)
        const item = data[i]
        detail.push({
          name: item.name,
          num: index,
          enabled: item.enabled,
          objectId: item.objectId,
          id: item.id,
          lastUpdated: moment(String(item.created_at)).format(
            'DD MMMM YYYY HH:mm:ss'
          ),
        })
      }
      return detail
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
  },
  methods: {
    handleEcommerce() {
      if (this.shop && this.shop.shopee && this.shop.shopee.product !== undefined) {
        if (this.shop.shopee.product === true) {
          this.isEcommerce = true
        } else {
          this.isEcommerce = false
        }
      }else{
        this.isEcommerce = false
      }
    },
    confirmdelete(item) {
      this.deleteModal = true
      this.addUnitModal = false
      this.itemDelete = item
    },
    addModal() {
      this.validateUnitname = ''
      this.itemId = ''
      this.itemObjectId = ''
      this.unitname = ''
      this.isactive = true
      this.namemodal = this.$i18n.t('addUnit')
      this.addUnitModal = true
    },
    editModal(item) {
      this.validateUnitname = ''
      this.itemId = item.id
      this.itemObjectId = item.objectId
      this.unitname = item.name
      this.isactive = item.enabled
      this.validateUnitname = ''
      this.namemodal = this.$i18n.t('editUnit')
      this.addUnitModal = true
    },
    async savedata() {
      this.isSuccess = false
      this.saveErrorMsg = ''
      this.loadingSaveButton = true
      this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const plan = this.users.currentPlan
      const headers = { shopObjectId: shopObjectId }
      
      let data = {
          name: this.unitname,
          enabled: this.isactive,
          shop: {
            id: this.shop.id,
            objectId: this.shop.objectId,
            branchName: this.shop.branchName,
          },
          shopId: this.shop.id,
          uid: uid,
      }
      if (this.itemObjectId != '') {
        data['id'] = this.itemId
        data['objectId'] = this.itemObjectId
      } 

      try {
        await local({
            method: 'post',
            url: '/api/v1.0/manage/' + uid + '/Unit/create',
            params: { shopObjectId: shopObjectId, plan: plan },
            data: data,
            headers: headers,
        })
        this.isSuccess = true
        this.addUnitModal = false
        this.unitname = ''
        this.loadingSaveButton = false
        this.getUnit()
      } catch (error) {
        this.loadingSaveButton = false

        if (error.code === 'ECONNABORTED') {
          this.saveErrorMsg = 'Request timed out'
        } else {
          this.saveErrorMsg = error.message
        }
      }
    },
    handlePopup() {
      if (this.unitname == '') {
        this.validateUnitname = 'กรุณากรอกหน่วยสินค้า'
      } else {
        this.validateUnitname = ''
        this.savedata()
      }
    },
    deleteUnit(item) {
      this.isDeleteSuccess = false
      this.loadingSaveButton = true
      const uid = `${localStorage.getItem('shopsUid')}`
      const plan = this.users.currentPlan
      const shopObjectId = this.shopObjectId
      const headers = { shopObjectId: shopObjectId }
      let data = {
        shopObjectId: shopObjectId,
        objectId: item,
      }

      local({
        method: 'post',
        url: '/api/v1.0/' + uid + '/Unit/softdelete',
        params: { plan: plan },
        data: data,
        headers: headers,
      })
        .then(
          setTimeout(() => {
            this.isDeleteSuccess = true
            this.deleteModal = false
            this.addUnitModal = false
            this.loadingSaveButton = false
            this.getUnit()
          }, 1000)
        )
        .catch((error) => {
          console.log(error)
        })
    },
    getUnit(page = 1) {
      this.searchLoadingButton = false
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const headers = { shopObjectId: shopObjectId }
      let params = {
        shopObjectId: shopObjectId,
        page: page,
        limit: this.meta_data.limit,
        sortattr: 'name',
        sortorder: 'asc'
      }

      local({
        url: '/api/v1.0/uid/' + uid + '/Unit/data',
        params: params,
        headers: headers,
        method: 'GET',
      }).then((res) => {
        let data = res.data
        this.data = data.data

        const paginate = data.paginate || {}
        this.meta_data.items = paginate.items || 0
        this.meta_data.last_page = paginate.pageCount || 0
        this.meta_data.current_page = paginate.currentPage || 0
        this.meta_data.itemCount = paginate.itemCount || 0
        this.meta_data.limit = paginate.perPage || 0

        this.searchLoadingButton = true
        this.loadingButton = true
      })
    },
    updateIsEnabled(e, item) {
      this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const plan = this.users.currentPlan
      const headers = { shopObjectId: shopObjectId }
      let data = {
        id: item.id,
        objectId: item.objectId,
        enabled: e,
        shop: {
          id: this.shop.id,
          objectId: this.shop.objectId,
          branchName: this.shop.branchName,
        },
        shopId: this.shop.id,
        uid: uid,
      }

      local({
        method: 'post',
        url: '/api/v1.0/manage/' + uid + '/Unit/create',
        params: { shopObjectId: shopObjectId, plan: plan },
        data: data,
        headers: headers,
      }).then(
          setTimeout(() => {
            this.getUnit()
          }, 1000)
        )
        .catch((error) => {
          console.log(error)
        })
    }, validator(val) {
      return val ? val.length >= 1 : false
    },
    updateItems() {
      let data = this.data
      let detail = []
      const currentPage = (this.meta_data.current_page || 1) - 1
      const limit = this.meta_data.limit || 50

      for (let i = 0; i < data.length; i++) {
        const index = (currentPage * limit) + (i + 1)
        const item = data[i]
        detail.push({
          name: item.name,
          num: index,
          enabled: item.enabled,
          objectId: item.objectId,
          id: item.id,
          lastUpdated: moment(String(item.created_at)).format(
            'DD/MM/YYYY HH:mm:ss'
          ),
        })
      }
      return detail
    },
  },
  watch: {
  '$i18n.locale': function(newLocale, oldLocale) {
    this.updateItems()
  },
},
}
</script>

<style>
.table th,
.table td {
  vertical-align: middle;
}
.cursor {
  cursor: pointer;
}
</style>
